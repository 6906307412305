.cards {
    margin-top: 150px !important;
    
}
.card {
    padding: 10px;
    margin-bottom: 10px;
}
.card-title h3{
    text-decoration: none !important;
    color: #674219;
}
.card-img img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

@media (max-width: 320px) {
    .cards {
        margin-top: 90px !important;
    }
}
@media (max-width: 576px) {
    .cards {
        margin-top: 90px !important;
    }
}
@media (max-width: 768px) {
    .cards {
        margin-top: 90px !important;
    }
}
@media (max-width: 992px) {
    .cards {
        margin-top: 90px !important;
    }
}
@media (max-width: 1200px) {
    .cards {
        margin-top: 90px !important;
    }
}
@media (max-width: 1400px) {
    .cards {
        margin-top: 90px !important;
    }
}