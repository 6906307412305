.custom-navbar {
  background-image: url('https://apicardapio.gigabytetelecom.com.br:3001/assets/images/bg_wood.jpg');
  /* background-image: url('http://192.168.100.37:3001/assets/images/bg_wood.jpg'); */
  background-size: cover;
  margin-bottom: 20px;
  box-shadow: 1px 1px 1px 1px #7a4e1d;
}

.navbar-text {
  color: #4C3112;
  font-weight: bold;
  font-size: 12px;
}

.custom-link {
  color: #4C3112 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  text-decoration: none;
  margin-right: 10px;
}

.custom-link:hover {
  color: #000 !important;
}

.custom-img img {
  width: 80px;
  height: 80px;
}

/* Estilo padrão para todos os tamanhos de tela */
.navbar-text {
  font-size: 20px !important;
}

/* Ajuste para telas menores, como smartphones */
@media (max-width: 768px) {
  .navbar-text {
    font-size: 12px !important;
    margin-left: -20px;
    color: #4C3112 !important;
    font-weight: bold !important;
  }

  .custom-img img {
    width: 50px;
    height: 45px;
  }

  .custom-link {
    margin-top: 10px !important;
    font-size: 14px !important;
  }
}